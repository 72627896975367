import React, { useState, useEffect, useMemo } from 'react'
import { useMetadata } from '@/api/hooks/useMetadata';
import { useLayers } from '@/api/hooks/useLayers';
import { useGlobalStore } from '@/stores/globalStore';
import AppLayout from '@/components/layout/AppLayout';
import qs from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import CourseMap from '@/shared/map/CourseMap';
import { useProjectStore } from '@/stores/projectStore';
import { useMapStore } from '@/stores/mapStore';
import { useLayerInfo } from '@/api/hooks/useLayerInfo';
import { linkLayerInfoProperties } from '@/components/Modal/linkProperties';

const ProjectPage: React.FC = () => {

    return (
        <AppLayout>
            <CourseMap />
            <SideEffector />
            <LayersInformer />
        </AppLayout>
    )
}

export default ProjectPage

const LayerData = ({ layer }) => {
    const { data } = useLayerInfo(Number(layer))
    const { metadata } = useMetadata()
    const addList = useProjectStore(state => state.addList)

    const currentNode = useMemo(() => {
        if (data && metadata && metadata.layers) {
            return metadata.layers[data?.info.type_uid]
        }
        return null
    }, [data?.info, metadata])

    const props = useMemo(() => linkLayerInfoProperties(currentNode?.plugin_data, data?.info.plugin_data), [currentNode, data?.info])

    useEffect(() => {
        const isPopup = props['popup']
        addList({
            [`${layer}`]: {
                id: layer,
                isPopup,
                info: data?.info,
                props,
            }
        })
    }, [props])


    return null
}

const LayersInformer = () => {
    const nodes = useProjectStore(state => state.nodes)
    const list = useProjectStore(state => state.list)
    const setLinks = useProjectStore(state => state.setLinks)

    useEffect(() => {
        // @ts-ignore
        const popups = Object.values(list).filter(v => v.isPopup)
        // @ts-ignore
        setLinks(popups.map(p => p.id))
    }, [list])
    
    return (
        <>
            {nodes.map(node => <LayerData key={node.id} layer={node.id} />)}
        </>
    )
}

const SideEffector = () => {
    const home = useGlobalStore(state => state.home)
    const layer = useGlobalStore(state => state.layer)
    const setHome = useGlobalStore(state => state.setHome)
    const setZoomSeat = useMapStore(state => state.setZoomSeat)
    const setLayer = useGlobalStore(state => state.setLayer)
    const setNodes = useProjectStore(state => state.setNodes)
    const setMeta = useProjectStore(state => state.setMeta)

    const { data } = useMetadata()
    const { layers } = useLayers()

    const navigate = useNavigate()
    const location = useLocation()
    const queryParams = qs.parse(location.search)
    const mapID = queryParams.map

    useEffect(() => {
        if (mapID) {
            setLayer(Number(mapID))

            // super experimental
            // setTimeout(() => {
            //     setZoomSeat(Number(mapID))
            // }, 500)
        }
    }, [])

    useEffect(() => {
        if (data) {
            setMeta(data.layers)
        }
    }, [data])


    useEffect(() => {
        if (!home && layers?.layer) {
            setHome(Number(layers.layer.id))
        }
        if (layers) {
            setNodes(layers.nodes)
        }
    }, [layers])

    useEffect(() => {
        const newQueries = { ...queryParams, map: layer }

        navigate({
            pathname: location.pathname,
            search: qs.stringify(newQueries, { sort: false, skipNull: true })
        })
    }, [layer])

    return null
}
