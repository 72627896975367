import { useMapStore } from '@/stores/mapStore'
import React, { useRef, useEffect, useState } from 'react'
import useImage from 'use-image'
import { useLayerView } from '@/api/hooks/useLayerView';
import { useGlobalStore } from '@/stores/globalStore'
import Map from './Map'
import styled from 'styled-components';
import { animated, useTransition } from 'react-spring';
import { easePoly } from 'd3-ease';
import { BackBtn } from '@/components/layout/Controls'
import RoomMap from './RoomMap'
import { useRoomStore } from '@/stores/roomStore'
import Loader from '@/components/Preloader/Loader';
import { useProjectStore } from '@/stores/projectStore';
import { useLayerInfo } from '@/api/hooks/useLayerInfo';
import LayerModal from '@/components/Modal/LayerModal';
import MapTooltip from './tooltip/MapTooltip';
import Emitter from '@/utils/helpers/emitter';

export const useLayerImage = (layer?: number | null) => {
    const { layerView } = useLayerView(layer)
    const image = layerView?.image
    const [src, status] = useImage(image || '')

    if (!image || !layer) return {
        isLoading: false,
        image: null
    }

    return {
        isLoading: status === 'loading',
        image: src
    }

}

const MapContainer: React.FC = () => {

    return (
        <MapWrapper>
            <HomeMap />
            <MapTooltip />
            <Room />
            {/* <BackButton /> */}
        </MapWrapper>
    )
}


const HomeMap = () => {
    const home = useGlobalStore(state => state.home)
    const { layerView } = useLayerView(home)

    return (
        <Map
            view={layerView?.view}
            polygons={layerView?.polygons}
        />
    )
}

const BackButton = () => {
    const layer = useGlobalStore(state => state.layer)
    const setLayer = useGlobalStore(state => state.setLayer)
    
    if (!layer) return null
    return <BackBtn onClick={() => setLayer(null)} />
}


export default MapContainer

export const convertToPluginList = (pluginData) => {
    if (!pluginData) return {}

    const arr: any = Object.values(pluginData)

    return arr.reduce((acc, val) => {
        if (!val.fields) return acc
        Object.values<any>(val.fields).forEach((value) => {
            const key = value.name
            acc[key] = value
        })

        return acc
    }, {})
}

export const convertInfoToPluginList = (pluginData) => {
    if (!pluginData) return {}

    const arr: any = Object.values(pluginData)

    return arr.reduce((acc, val) => {
        if (!val.fields) return acc
        Object.values<any>(val.fields).forEach((value) => {
            const key = value.field_id
            acc[key] = value
        })

        return acc
    }, {})
}

const Room = () => {
    const layer = useGlobalStore(state => state.layer)
    const setLayer = useGlobalStore(state => state.setLayer)
    const setSize = useRoomStore(state => state.setSize)
    const prevPosition = useMapStore(state => state.prevPosition)

    const meta = useProjectStore(state => state.meta)
    const [open, setOpen] = useState(false)
    const [isPopup, setIsPopup] = useState(null)

    const { isLoading, image } = useLayerImage(layer)
    const { layerView } = useLayerView(layer)
    const { data } = useLayerInfo(layer)

    // const uid = layerView?.view.map_node.type_uid
    const uid = data?.info.type_uid
    const metadata = uid ? meta[uid] : null
    const list = convertToPluginList(metadata?.plugin_data)

    const transitions = useTransition(!!layer, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: {
            duration: 300,
            easing: easePoly.exponent(2),
        },
    })

    const transitionsRoom = useTransition(image && !isLoading, {
        from: { opacity: 0, scale: 0.8 },
        enter: { opacity: 1, scale: 1 },
        leave: { opacity: 0, scale: 0.8 },
        config: {
            duration: 300,
            easing: easePoly.exponent(2),
        },
    })

    useEffect(() => {
        const popupId = list['popup'] ? list['popup'].id : null 
        console.log(uid, metadata?.plugin_data, list, popupId);

        if (!popupId) return

        const popupAttrs = convertInfoToPluginList(data?.info?.plugin_data)
        const isLayerPopup = popupId ? popupAttrs[popupId]?.value : false
        
        setIsPopup(isLayerPopup)
        setOpen(true)

        console.log(popupAttrs, isLayerPopup);
        

    }, [uid, data?.info])

    useEffect(() => {
        if (isPopup && isPopup == null) return
        if (image) {
            setSize([image.width, image.height])
        }
    }, [image, isPopup])

    return (
        <>
            {
                isPopup === false ? transitions((style, item) => item ? (
                    <RoomWrapper style={style}>
                        {transitionsRoom((style, item) => item ? (
                            <AbsoluteWrapper style={style}>
                                <RoomMap
                                    polygons={layerView?.polygons}
                                    image={image}
                                />
                            </AbsoluteWrapper>
                        ) : (
                            <AbsoluteWrapper style={style}>
                                <Loader />
                            </AbsoluteWrapper>
                        ))}
                    </RoomWrapper>
                ) : '') : (
                    <LayerModal
                        isOpen={open}
                        data={data?.info}
                        onClose={() => {
                            setIsPopup(null)
                            setOpen(false)
                            setLayer(null)

                            Emitter.emit('unzoom', { pos: prevPosition })
                        }}
                    />
                )
            }
        </>
    )
}

const AbsoluteWrapper = styled(animated.div)`
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

const RoomWrapper = styled(animated.div)`
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #4c4d4f;
`

const MapWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`