import React from 'react'
import styled from 'styled-components';
import { media } from '@/ui/media';

const BrandLogo = () => {
  return (
    <Wrapper href="https://make-maps.com/" target="_blank">
        <svg width="120" height="12" viewBox="0 0 213 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M115.633 11.849C115.633 16.6791 111.718 20.5947 106.888 20.5947C102.058 20.5947 98.142 16.6791 98.142 11.849C98.142 7.01893 102.058 3.10336 106.888 3.10336C111.718 3.10336 115.633 7.01893 115.633 11.849ZM100.549 11.849C100.549 15.3497 103.387 18.1875 106.888 18.1875C110.388 18.1875 113.226 15.3497 113.226 11.849C113.226 8.34837 110.388 5.51052 106.888 5.51052C103.387 5.51052 100.549 8.34837 100.549 11.849Z" fill="#568218"/>
            <path d="M2.39361 1.1268H3.19888L12.3496 11.156L21.4857 1.1268H22.3495L24.1065 21.8441H21.4564L20.1241 6.25121L12.3789 14.8309H12.0861L4.57515 6.38298L3.40385 21.8441H0.783081L2.39361 1.1268Z" fill="black"/>
            <path d="M36.7711 1.11216H38.3084L47.2835 21.8441H44.3259L42.364 17.3053H31.9834L29.8605 21.8441H27.0786L36.7711 1.11216ZM41.3099 14.8456L39.4358 10.4972C39.2015 9.95055 38.9722 9.38443 38.7477 8.79878L38.162 7.14433C37.7911 6.07064 37.5715 5.33858 37.5032 4.94815H37.3275C37.2494 5.33858 37.0737 5.89494 36.8004 6.61724C36.8004 6.61724 36.5515 7.25169 36.0537 8.5206C35.8292 9.0672 35.5852 9.62356 35.3216 10.1897L33.1401 14.8456H41.3099Z" fill="black"/>
            <path d="M50.3435 1.11216H53.1253V11.4342L63.8866 1.11216H67.3858L56.1121 11.8588L67.869 21.8441H63.5937L53.1253 12.6933V21.8441H50.3435V1.11216Z" fill="black"/>
            <path d="M70.8997 1.11216H86.3754V3.57188H73.6083V9.16481H84.9845V11.6245H73.6083V19.3843H86.6682V21.8441H70.8997V1.11216Z" fill="black"/>
            <path d="M126.668 1.1268H127.473L136.624 11.156L145.76 1.1268H146.624L148.381 21.8441H145.731L144.398 6.25121L136.653 14.8309H136.36L128.849 6.38298L127.678 21.8441H125.057L126.668 1.1268Z" fill="black"/>
            <path d="M161.045 1.11216H162.583L171.558 21.8441H168.6L166.638 17.3053H156.258L154.135 21.8441H151.353L161.045 1.11216ZM165.584 14.8456L163.71 10.4972C163.476 9.95055 163.246 9.38443 163.022 8.79878L162.436 7.14433C162.065 6.07064 161.846 5.33858 161.777 4.94815H161.602C161.524 5.33858 161.348 5.89494 161.075 6.61724C161.075 6.61724 160.826 7.25169 160.328 8.5206C160.104 9.0672 159.859 9.62356 159.596 10.1897L157.414 14.8456H165.584Z" fill="black"/>
            <path d="M174.618 1.11216H182.831C185.604 1.11216 187.819 1.73685 189.479 2.98623C191.275 4.33322 192.173 6.27073 192.173 8.79878C192.173 11.4342 191.226 13.5328 189.332 15.0945C187.536 16.5684 185.233 17.3053 182.422 17.3053H177.326V21.8441H174.618V1.11216ZM182.788 14.8456C184.779 14.8456 186.35 14.3478 187.502 13.3522C188.703 12.3175 189.303 10.8583 189.303 8.97447C189.303 7.16873 188.629 5.79246 187.282 4.84566C186.072 3.99647 184.447 3.57188 182.407 3.57188H177.326V14.8456H182.788Z" fill="black"/>
            <path d="M196.711 17.1735C198.527 18.3448 200.372 19.1159 202.246 19.4868C202.88 19.6137 203.524 19.6772 204.178 19.6772C204.832 19.6772 205.506 19.6186 206.199 19.5015C206.902 19.3746 207.521 19.1696 208.058 18.8866C209.269 18.2423 209.874 17.2516 209.874 15.9144C209.874 14.5381 208.859 13.5767 206.828 13.0301C206.233 12.8739 205.599 12.7324 204.925 12.6055L202.831 12.2395C200.167 11.7807 198.307 11.1072 197.253 10.219C196.277 9.40883 195.789 8.31562 195.789 6.93935C195.789 5.06528 196.687 3.54748 198.483 2.38594C200.084 1.34154 201.972 0.819336 204.149 0.819336C206.238 0.819336 208.117 1.16096 209.786 1.84422C210.381 2.07848 211.001 2.3713 211.645 2.72269L210.65 5.1092C209.234 4.26001 207.565 3.70853 205.642 3.45475C205.037 3.3669 204.452 3.32298 203.886 3.32298C202.587 3.32298 201.44 3.57188 200.445 4.06968C199.283 4.65532 198.703 5.46547 198.703 6.50011C198.703 7.59332 199.439 8.39371 200.913 8.90127C201.577 9.13553 202.329 9.32586 203.168 9.47227L205.774 9.92615C207.97 10.3361 209.493 10.7997 210.342 11.3171C211.191 11.8344 211.816 12.4151 212.216 13.0594C212.626 13.6938 212.831 14.543 212.831 15.6069C212.831 16.6611 212.582 17.6079 212.085 18.4473C211.587 19.2867 210.923 19.9798 210.093 20.5264C208.473 21.6098 206.409 22.1515 203.9 22.1515C201.558 22.1515 199.357 21.644 197.297 20.6289C196.623 20.297 195.999 19.9163 195.423 19.4868L196.711 17.1735Z" fill="black"/>
        </svg>
    </Wrapper>
  )
}

export default BrandLogo

const Wrapper = styled.a`
    background: linear-gradient(164.21deg, #F8F8F7 12.84%, #A8A89D 91.47%);
    border-radius: 40px;
    padding: 8px 16px;
    z-index: 1001;

    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    bottom: 24px;
    left: 24px;

    outline: none;
    text-decoration: none;
    border: none;

    /* ${media.lg`
        padding: 10px 18px;
    `}

    @media screen and (orientation:landscape) and (max-width: 1024px) {
        padding: 10px 18px;

        svg {
            width: 159px;
            height: 16px;
        }
    } */
`