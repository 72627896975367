import React, { useEffect, useState, useRef, useMemo } from "react";
import { useMapStore } from "@/stores/mapStore";
import styled, { css } from "styled-components";
import { useMetadata } from "@/api/hooks/useMetadata";
import { linkLayerInfoProperties } from "@/components/Modal/linkProperties";
import { useLayerInfo } from "@/api/hooks/useLayerInfo";
import StatusIcon from "@/components/icons/Status";
import media from "@/ui/media";

const MapTooltip = () => {
    const ttRef = useRef(null)
    const tooltipPoint = useMapStore(state => state.tooltipPoint)
    const tooltip = useMapStore(state => state.tooltip)

    const [visible, setVisible] = useState(false)

    // @ts-ignore
    const containerWidth = ttRef.current?.offsetWidth / 2 || 0
    // @ts-ignore
    const containerHeight= ttRef.current?.offsetHeight || 0

    let timer

    useEffect(() => {
        if (!tooltip) {
            clearTimeout(timer)
            setVisible(false)
            return
        } else {
            setVisible(false)
        }

        timer = setTimeout(function () {
            setVisible(true)
        }, 500)

        return () => {
            clearTimeout(timer)
        }
    }, [tooltip])

    return (
        <TooltipWrapper 
            ref={ttRef}
            $visible={visible}
            style={{ transform: `translateX(${tooltipPoint.x - containerWidth}px) translateY(${tooltipPoint.y - containerHeight}px)`}}
        >
            <TooltipContent />
        </TooltipWrapper>
    )
}

export default MapTooltip


const ObjectTitle = ({ name, homeSq = '' }) => {
    return (
        <Name>{name} {homeSq && <span>{homeSq} м<sup>2</sup></span>}</Name>
    )
}

const TooltipContent = React.memo(() => {
    const tooltip = useMapStore(state => state.tooltip)
    const { metadata } = useMetadata()
    const { data } = useLayerInfo(Number(tooltip))

    const currentNode = useMemo(() => {
        if (data && metadata && metadata.layers) {
            return metadata.layers[data?.info.type_uid]
        }
        return null
    }, [data?.info, metadata])

    const props = useMemo(() => linkLayerInfoProperties(currentNode?.plugin_data, data?.info.plugin_data), [currentNode, data?.info])

    return (
        <Wrapper>
            <div>
                <ObjectTitle
                    name={data?.info.name}
                    // homeSq={props['home_sq']}
                />
{/* 
                <ShowValid valid={Boolean(props['description'])}>
                    <Description>{props['description']}</Description>
                </ShowValid> */}
            </div>
            

            {/* <Row>
                <ShowValid valid={Boolean(props['land_sq'])}>
                    <Property>
                        <StatusRow>
                            <StatusIcon type="square" />
                            <PropertyValue>{props['land_sq']} соток</PropertyValue>
                        </StatusRow>
                    </Property>
                </ShowValid>
          
                <ShowValid valid={Boolean(props['status'])}>
                    <Property>
                        {props['status'] == 0 && (
                            <StatusRow>
                                <StatusIcon type="busy" />
                                <PropertyValue>Занят</PropertyValue>
                            </StatusRow>
                        )}
                        {props['status'] == 1 && (
                            <StatusRow>
                                <StatusIcon type="booked" />
                                <PropertyValue>Забронирован</PropertyValue>
                            </StatusRow>
                        )}
                        {props['status'] == 2 && (
                            <StatusRow>
                                <StatusIcon type="available" />
                                <PropertyValue>Свободен</PropertyValue>
                            </StatusRow>
                        )}
                    </Property>
                </ShowValid>
                
            </Row> */}
        </Wrapper>
    )
})

export const ShowValid = ({ valid, children }) => {

    if (!valid) return null

    return (
        <>
            {children}
        </>
    )
}


const Description = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #97968F;
`

const TooltipWrapper = styled.div<{ $visible: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    will-change: transform;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0s, transform 0.1s;

    ${({ $visible }) => $visible && css`
        opacity: 1;
        visibility: visible;
        transition: opacity 0.2s, transform 0.1s;
    `}
`

const Wrapper = styled.div`
    background: #FFFFFF;
    border-radius: 10px;
    padding: 16px 24px;
    font-family: 'IBM Plex Sans';
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    color: #000000;
    min-width: 110px;
    display: flex;
    flex-direction: column;
    gap: 6px;

    &::after {
        content: "";
        position: absolute;
        z-index: -1;
        bottom: -16px;
        transform: translateX(-50%);
        left: 50%;
        width: 110px;
        height: 34px;
        background-image: url("data:image/svg+xml,%3Csvg width='110' height='34' viewBox='0 0 110 34' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M64.2976 18.5518L56.8658 32.8935C56.1197 34.3333 54.0603 34.3333 53.3143 32.8935L45.8824 18.5518C44.6793 16.23 42.2823 14.7724 39.6673 14.7724H0V-9.53674e-05H110V14.7724H70.5127C67.8977 14.7724 65.5007 16.23 64.2976 18.5518Z' fill='white'/%3E%3C/svg%3E%0A");
    }
`

const TitleRow = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    span {
        position: relative;
    }

    sup {
        font-size: 10px;
        line-height: 13px;
        position: absolute;
        top: 0.2em;
    }
`
const Row = styled.div`
    display: flex;
    gap: 16px;
`


const Property = styled.div`
    display: flex;
    flex-direction: column;
`

const PropertyValue = styled.div`
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    color: #000000;
    padding-right: 8px;
`

const StatusRow = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;

    ${PropertyValue} {
        margin-left: 8px;
    }
`

const Name = styled.h2`
    font-family: 'IBM Plex Sans';
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #000000;
    text-align: center;

    span {
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        margin-left: 8px;
    }
`
