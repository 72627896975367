import React from 'react'

const ArrowIcon = () => {
    return (
        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.3075 3.37365L5.15392 9.6864C5.04584 9.79728 4.98535 9.946 4.98535 10.1008C4.98535 10.2557 5.04584 10.4044 5.15392 10.5153L11.3075 16.8304C11.3579 16.8821 11.4181 16.9232 11.4846 16.9513C11.5511 16.9793 11.6225 16.9938 11.6947 16.9938C11.7668 16.9938 11.8383 16.9793 11.9048 16.9513C11.9713 16.9232 12.0314 16.8821 12.0818 16.8304C12.1854 16.7244 12.2434 16.582 12.2434 16.4338C12.2434 16.2855 12.1854 16.1432 12.0818 16.0372L6.29511 10.1008L12.0818 4.16572C12.185 4.05973 12.2428 3.91763 12.2428 3.76969C12.2428 3.62174 12.185 3.47964 12.0818 3.37365C12.0314 3.32195 11.9713 3.28085 11.9048 3.25279C11.8383 3.22472 11.7668 3.21027 11.6947 3.21027C11.6225 3.21027 11.5511 3.22472 11.4846 3.25279C11.4181 3.28085 11.3579 3.32195 11.3075 3.37365Z" fill="#2F2F2F" />
        </svg>

    )
}

export const SmallArrowIcon = () => {
    return (
        <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.09524 1L1.65681 8.81034C1.289 9.19655 1.289 9.80345 1.65681 10.1897L9.09524 18" stroke="#97968F" strokeWidth="1.5" strokeLinecap="round"/>
        </svg>
    )
}

export default ArrowIcon