export const linkLayerInfoProperties = (node, meta) => {
    if (!node || !meta) return []
    const keys = Object.keys(node)

    const data = keys.map(key => meta[key]).filter(v => v).find(item => item.fields)?.fields
    const nodeData = keys.map(key => node[key]).filter(v => v).find(item => item.fields)?.fields

    if (!data || !nodeData) return []

    const props = data.map(item => {
        const info = nodeData.find(n => n.id == item.field_id)

        if (!info || typeof info.value === 'object') return null

        return {
            name: String(info.name),
            value: String(item.value)
        }
    }).filter(v => v)

    return props.reduce((acc, val) => ({ ...acc, [val.name]: val.value }), {})
}