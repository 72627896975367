import create from 'zustand'

type PrevPosition = { x: number, y: number, scale: number }

type MapState = {
    initialized: boolean
    isLoading: boolean
    isAnimating: boolean
    size: [number, number]
    homeSize: [number, number]
    stage: any
    layerRef: any
    zoomSeat: number | null
    tooltip: string | null
    tooltipPoint: {
        x: number
        y: number
    }
    prevPosition: PrevPosition | null
    setPrevPosition: (pos: PrevPosition | null) => void
    setTooltip: (seat: string | null) => void
    setIsAnimating: (isAnimating: boolean) => void
    setTooltipPoint: (tooltipPoint: { x: number, y: number }) => void
    setInitialized: (initialized: boolean) => void
    setIsLoading: (isLoading: boolean) => void
    setSize: (size: [number, number]) => void
    setHomeSize: (homeSize: [number, number]) => void
    setStage: (stage) => void
    setLayerRef: (layerRef) => void
    setZoomSeat: (seat: number | null) => void
}

export const useMapStore = create<MapState>(set => ({
    initialized: false,
    isLoading: false,
    isAnimating: false,
    homeSize: [0, 0],
    size: [0, 0],
    stage: null,
    layerRef: null,
    zoomSeat: null,
    tooltip: null,
    tooltipPoint: {
        x: 0,
        y: 0,
    },
    prevPosition: null,
    setPrevPosition: (prevPosition) => set(() => ({ prevPosition })),
    setTooltip: (tooltip) => set(() => ({ tooltip })),
    setTooltipPoint: (tooltipPoint) => set(() => ({ tooltipPoint })),
    setIsAnimating: (isAnimating) => set(() => ({ isAnimating })),
    setInitialized: (initialized) => set(() => ({ initialized })),
    setIsLoading: (isLoading) => set(() => ({ isLoading })),
    setHomeSize: (homeSize) => set(() => ({ homeSize })),
    setSize: (size) => set(() => ({ size })),
    setStage: (stage) => set(() => ({ stage, initialized: true })),
    setLayerRef: (layerRef) => set(() => ({ layerRef, initialized: true })),
    setZoomSeat: (zoomSeat) => set(() => ({ zoomSeat })),
}))

type MapPointType = {
    x: number
    y: number
}

interface OnRescaleProps {
    scale: number
    point: MapPointType
}