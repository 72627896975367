import useWindowSize from '@/hooks/useWindowSize'
import { useMapStore } from '@/stores/mapStore'
import { easePoly } from 'd3-ease'
import React, { useState } from 'react'
import { animated, useTransition } from 'react-spring'
import styled from 'styled-components'
import ArrowIcon from '../icons/ArrowIcon'
import CloseIcon from '../icons/CloseIcon'
import HomeIcon from '../icons/HomeIcon'
import MenuIcon from '../icons/MenuIcon'
import MinusIcon from '../icons/MinusIcon'
import PlusIcon from '../icons/PlusIcon'
import { useGlobalStore } from '@/stores/globalStore'
import { boundFunc, limitScale, moveStage } from '@/shared/map/MapStage'
import { useRoomStore } from '@/stores/roomStore'
import { boundRoomFunc, limitRoomScale } from '@/shared/map/RoomMap'
import { useLayerView } from '@/api/hooks/useLayerView'
import useResponsive from '@/hooks/useResponsive'
import Emitter from '@/utils/helpers/emitter'


const Controls: React.FC = () => {
    const layerRef = useMapStore(state => state.layerRef)
    const size = useMapStore(state => state.homeSize)
    const roomStage = useRoomStore(state => state.stage)
    const roomSize = useRoomStore(state => state.size)
    const layer = useGlobalStore(state => state.layer)
    
    const { width, height } = useWindowSize()
    const { isDesktop } = useResponsive()


    const scaleRelativeToPoint = (point, increaseScale) => {
        if (!layer) {
            const scaleBy = 1.25
            const oldScale = layerRef.scaleX()
            
            const mousePointTo = {
                x: point.x / oldScale - layerRef.x() / oldScale,
                y: point.y / oldScale - layerRef.y() / oldScale
            }
    
            const newScale = increaseScale ? oldScale * scaleBy : oldScale / scaleBy
            const resultScale = limitScale(newScale, width, height, size[0], size[1])
            const x = (point.x / resultScale - mousePointTo.x) * resultScale
            const y = (point.y / resultScale - mousePointTo.y) * resultScale
    
            const newPos = { x, y }
            const boundPos = boundFunc(newPos, resultScale, width, height, size[0], size[1])
            
            Emitter.emit('scale', {
                pos: {
                    x: boundPos.x,
                    y: boundPos.y,
                    scale: resultScale
                }
            })
        } else {
            const scaleBy = 1.25
            const oldScale = roomStage.scaleX()
            
            const mousePointTo = {
                x: point.x / oldScale - roomStage.x() / oldScale,
                y: point.y / oldScale - roomStage.y() / oldScale
            }
    
            const newScale = increaseScale ? oldScale * scaleBy : oldScale / scaleBy
            const resultScale = limitRoomScale(newScale, width, height, roomSize[0], roomSize[1])
            const x = (point.x / resultScale - mousePointTo.x) * resultScale
            const y = (point.y / resultScale - mousePointTo.y) * resultScale
    
            const newPos = { x, y }
            const boundPos = boundRoomFunc(newPos, resultScale, width, height, roomSize[0], roomSize[1])
            
            // roomStage.scale({ x: resultScale, y: resultScale })
            // roomStage.position(boundPos)
            // roomStage.batchDraw()

            moveStage(roomStage, boundPos, 0.3, resultScale)
        }
    }

    if (!isDesktop && layer) return null
    
    return (
        <ControlsWrapper>
            <ControlButton onClick={scaleRelativeToPoint.bind(null, {
                x: width / 2,
                y: height / 2
            }, true)}>
                <PlusIcon />
            </ControlButton>
            <ControlButton onClick={scaleRelativeToPoint.bind(null, {
                x: width / 2,
                y: height / 2
            }, false)}>
                <MinusIcon />
            </ControlButton>
        </ControlsWrapper>
    )
}

export default Controls

export const MenuBtn = () => {
    const [open, setOpen] = useState(false)

    const transitions = useTransition(open, {
        from: { opacity: 0, scale: 0.8 },
        enter: { opacity: 1, scale: 1 },
        leave: { opacity: 0, scale: 0.8 },
        config: {
            duration: 100,
            easing: easePoly.exponent(2),
        },
    })

    return (
        <Menu>
            <MenuButton onClick={() => setOpen(true)}>
                <MenuIcon />
            </MenuButton>

            {transitions((style, item) => item ? (
                <Inner style={style}>
                    <CloseButton onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </CloseButton>

                    <p>Map designed by LightsON</p>
                    <p>Service provided by LightsON</p>
                    {/* <p>VEEEEEEEEEEEEEEEEEEERYYYYY LOOOOOOONG LINE</p> */}
                </Inner>
            ) : '')}
            
        </Menu>
    )
}

export const HomeBtn = () => {
    const setLayer = useGlobalStore(state => state.setLayer)

    return (
        <HomeButton onClick={() => setLayer(null)} >
            <HomeIcon />
        </HomeButton>
    )
}


export const BackBtn = ({ onClick }) => {
    const layer = useGlobalStore(state => state.layer)
    const home = useGlobalStore(state => state.home)
    const setLayer = useGlobalStore(state => state.setLayer)
    const { layerView } = useLayerView(layer)

    const handleBack = () => {
        const parent = layerView?.view.map_node.parent_id
        
        if (parent && parent != home) {
            setLayer(Number(parent))
        } else {
            setLayer(null)
        }
    }

    return (
        <BackButton onClick={handleBack}>
            <ArrowIcon />
            Назад
        </BackButton>
    )
}

const CloseButton = styled.div`
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
`

const Menu = styled.div`

`
const Inner = styled(animated.div)`
    position: absolute;
    top: 5%;
    right: 5%;
    margin-left: 5%;
    padding: 50px 16px 32px 16px;
    background: linear-gradient(169.7deg, #FFFFFF 2.81%, rgba(255, 255, 255, 0) 153.96%);
    backdrop-filter: blur(42px);
    border-radius: 12px;
    transform-origin: top right;
    display: flex;
    flex-direction: column;
    z-index: 1102;

    p {
        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }
`

const ControlsWrapper = styled.div`
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translateY(-50%);
    z-index: 1101;
`

const ControlButton = styled.div`
    background: linear-gradient(168.74deg, #FFFFFF 2.83%, rgba(255, 255, 255, 0) 159.86%);
    backdrop-filter: blur(42px);
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    transition: all 0.3s;

    cursor: pointer;

    &:not(:last-child) {
        margin-bottom: 8px;
    }

    &:active {
        transform: scale(0.8);
    }
`

const HomeButton = styled(ControlButton)`
    position: absolute;
    top: 5%;
    left: 5%;
    z-index: 1101;
`

const MenuButton = styled(ControlButton)`
    position: absolute;
    top: 5%;
    right: 5%;
    z-index: 1101;
`

const BackButton = styled.div`
    position: absolute;
    top: 5%;
    left: 5%;
    margin-left: 50px;
    background: linear-gradient(160.69deg, #FFFFFF 2.98%, rgba(255, 255, 255, 0) 142.89%);
    border-radius: 28px;
    padding: 10px 20px 10px 10px;
    height: 40px;
    min-width: 100px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #2F2F2F;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    z-index: 1011;

    cursor: pointer;

    &:not(:last-child) {
        margin-bottom: 8px;
    }

    &:active {
        transform: scale(0.95);
    }

`