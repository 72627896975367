import create from 'zustand'

type GlobalState = {
    isLoading: boolean
    layer: number | null | undefined
    home: number | null
    project: {
        projectId: number
        workspaceId: number
    }
    setProject: (project) => void
    setLoading: (isLoading: boolean) => void
    setLayer: (layer: number | null | undefined) => void
    setHome: (home: number) => void
}

export const useGlobalStore = create<GlobalState>(set => ({
    isLoading: true,
    layer: null,
    home: null,
    project: {
        projectId: 0,
        workspaceId: 0
    },
    setProject: (project) => set(() => ({ project })),
    setLoading: (isLoading) => set(() => ({ isLoading })),
    setLayer: (layer) => set(() => ({ layer })),
    setHome: (home) => set(() => ({ home })),
}))
