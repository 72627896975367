import { StatusResponseEnum } from "@/api"
import { useMe } from "@/api/hooks/useMe"
import { useMetadata } from "@/api/hooks/useMetadata"
import { useGlobalStore } from "@/stores/globalStore"
import { RolesEnum, useUserStore } from "@/stores/userStore"
import { useEffect, useState } from "react"
import { Navigate, Outlet, useParams } from "react-router-dom"
import Preloader from '@/components/Preloader/Preloader';
import { ProjectService } from "@/api/services/project.service"
import { useToast } from "@/shared/toast/useToast"
import MapLoader from "@/shared/map/MapLoader"

function LoadingComponent() {
    return (
        <MapLoader />
    )
}

const extractCategories = (pluginData) => {
    const plugin: any = Object.values(pluginData).find((obj: any) => obj?.categories)
    if (!plugin) return []
    return plugin.categories
}

const useAuth = () => {
    const { user } = useMe()
    const { workspaceId, projectId } = useParams<{ workspaceId: string, projectId: string }>()
    const { enqueueToast } = useToast()

    // selectors
    const setUser = useUserStore(state => state.setUser)
    const setCategories = useUserStore(state => state.setCategories)
    const setAuthenticated = useUserStore(state => state.setAuthenticated)
    const setRole = useUserStore(state => state.setRole)
    const project = useGlobalStore(state => state.project)
    const setProject = useGlobalStore(state => state.setProject)

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const fetchData = async () => {

        setProject({ workspaceId: Number(workspaceId), projectId: Number(projectId) })

        try {
            const response = await ProjectService.getMetadata({ workspaceId: Number(workspaceId), projectId: Number(projectId) })
            if (response && response.data.status === StatusResponseEnum.Success) {
                const categories = extractCategories(response.data.project_user_data?.plugin_data)
                setCategories(categories)
                setIsAuthenticated(true)
                const isAdmin = response.data.project_access === RolesEnum.Admin
                const isUser = response.data.project_access === RolesEnum.User
                if (isAdmin) {
                    setRole(RolesEnum.Admin)
                }
                else if (isUser) {
                    setRole(RolesEnum.User)
                } else {
                    setRole(RolesEnum.None)

                }
            }
        } catch (e) {
            enqueueToast({ title: 'Ошибка!', message: 'Не удалось загрузить метаданные проекта' }, { variant: 'error' })
        }

        setIsLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        if (!user) return
        setUser(user)

        if (user.role === RolesEnum.Admin) {
            setRole(RolesEnum.Admin)
        }

        setAuthenticated(true)
    }, [user])

    return { isAuthenticated, isLoading }
}

const ProtectedRoutes = () => {
    const { workspaceId, projectId } = useParams<{ workspaceId: string, projectId: string }>()


    const isAuth = useAuth()

    if (isAuth.isLoading) {
        return <LoadingComponent />
    }
    
    if (!isAuth.isAuthenticated) {
        return <Navigate to={`/login?workspace_id=${workspaceId}&project_id=${projectId}`} />
    }

    return <Outlet />
}

export default ProtectedRoutes