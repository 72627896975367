import { TreeNodeData } from '@/utils/helpers/tree.helpers'
import create from 'zustand'

type ProjectState = {
    nodes: TreeNodeData[]
    setNodes: (nodes: TreeNodeData[]) => void
    meta: any[]
    setMeta: (meta: any[]) => void
    list: any
    addList: (list: any) => void
    links: number[]
    setLinks: (list: number[]) => void
}

export const useProjectStore = create<ProjectState>(set => ({
    nodes: [],
    setNodes: (nodes) => set(() => ({ nodes })),
    list: {},
    addList: (list) => set(state => ({ list: {  ...state.list, ...list} })),
    meta: [],
    setMeta: (meta) => set(() => ({ meta })),
    links: [],
    setLinks: (links) => set(() => ({ links })),
}))
