import { useMapStore } from '@/stores/mapStore'
import React, { useEffect, useRef } from 'react'
import { Group, Layer } from 'react-konva'
import Polygon from './Polygon'
import { PolygonType } from '@/api/services/layer.service'
import MapStage, { boundFunc, getCenter, getDistance, limitScale, moveStage } from './MapStage'
import MapCoverLayer from './MapCoverLayer'
import Tooltip from './tooltip/Tooltip'
import { animated, useSpring, config } from '@react-spring/konva'
import { useGesture } from '@use-gesture/react'
import useWindowSize from '@/hooks/useWindowSize'
import { easeBounceInOut, easeQuadOut, easeSinInOut } from 'd3-ease'
import { useGlobalStore } from '@/stores/globalStore'
import Konva from 'konva'
import { Vector2d } from 'konva/lib/types'
import { KonvaEventObject } from 'konva/lib/Node'

let initialScale = 1
const ZeroVector: Vector2d = { x: 0, y: 0 }
const scaleBy = 1.25

const Map: React.FC<{ view: any, polygons: PolygonType[] | undefined }> = ({ view, polygons = [] }) => {
    
    const layerRef = useRef(null)
    const lastCenter = React.useRef<Vector2d | null>(null)
    const lastDist = React.useRef<number>(0)

    const homeSize = useMapStore(state => state.homeSize)
    const stageRef = useMapStore(state => state.stage)
    const stage = layerRef.current

    const layer = useGlobalStore(state => state.layer)
    const { width, height } = useWindowSize()

    const [style, api] = useSpring(() => ({
        x: 0,
        y: 0,
        scale: { x: 1, y: 1 },
        config: {
            duration: 0
        }
    }))

    const resize = React.useCallback(() => {
        if (!homeSize[0] || !homeSize[1]) return

        const [stageWidth, stageHeight] = homeSize
        const scaleX = width / stageWidth
        const scaleY = height / stageHeight
        const newScale = Number(Math.max(scaleX, scaleY))

        const pointX = Number(-(stageWidth * newScale - width) / 2)
        const pointY = Number(-(stageHeight * newScale - height) / 2)

        if (Number.isNaN(newScale) || Number.isNaN(pointX) || Number.isNaN(pointY)) return

        const resultScale = limitScale(newScale, width, height, stageWidth, stageHeight)
        initialScale = resultScale

        api.start({
            x: pointX,
            y: pointY,
            scale: {
                x: resultScale,
                y: resultScale
            },
            config: {
                duration: 0,
                easing: easeSinInOut,
            }
        })

        // if (zoomSeat) {
        //     moveTo(Number(zoomSeat))
        // }

    }, [layerRef, homeSize, width, height])

    const dragBoundFunc = React.useCallback(
        (pos) => boundFunc(pos, layerRef.current.scaleX(), width, height, homeSize[0], homeSize[1]),
        [homeSize, layerRef, width, height]
    )

    // useEffect(() => {
    //     resize()
    // }, [width, height, homeSize])


    useGesture(
        {
            onMouseMove: ({ event }) => {
                // do not move when scaled to point
                if (layer) return

                const [stageWidth, stageHeight] = homeSize

                const offsetX = stageWidth * stage?.scaleX() - width
                const offsetY = stageHeight * stage?.scaleX() - height
    
                const dX = ((event.pageX / width) * offsetX)
                const dY = ((event.pageY / height) * offsetY)

                api.start({
                    x: -dX,
                    y: -dY,
                    config: {
                        mass: 1,
                        tension: 120,
                        friction: 150,
                        easing: easeSinInOut,
                    }
                })
            },
        },
        {
            target: layerRef,
        }
    )

    return (
        <MapStage>
            {/* //@ts-ignore */}
            {/* <animated.Layer
                ref={layerRef}
                native
                {...style}
            > */}
                <MapCoverLayer view={view} />
                <Polygons polygons={polygons} />
                <Tooltip />
            {/* </animated.Layer> */}
        </MapStage>
    )
}

export default Map

const Polygons = ({ polygons }) => {
    const [width, height] = useMapStore(state => state.homeSize)

    return (
        <Group>
            {polygons.map(({ id, polygon }) => (
                <Polygon
                    id={id}
                    key={id}
                    width={width}
                    height={height}
                    polygon={polygon}
                />
            ))}
        </Group>
    )
}